import React from 'react';
import { Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledCardsLoader = styled('div')(({ theme }) => ({
  label: 'LoaderContainer',

  width: '53.375rem',
  [theme.breakpoints.down('md')]: {
    width: '90%',
  },
}));

const StyledBoardLoader = styled('div')(({ theme }) => ({
  label: 'LoaderContainer',

  width: '25rem',
  [theme.breakpoints.down('xs')]: {
    width: '85%',
  },
}));

export const Loader = () => {
  return (
    <>
      <StyledBoardLoader>
        <Skeleton
          variant="rounded"
          width="100%"
          height="11.25rem"
          style={{ marginBottom: '13vh', borderRadius: '15px' }}
        />
      </StyledBoardLoader>
      <Skeleton
        variant="rounded"
        width="14.375rem"
        height="2.813rem"
        style={{ marginBottom: '10vh' }}
      />
      <Skeleton
        variant="rounded"
        width="10rem"
        height="1.875rem"
        sx={{ marginBottom: '1vh' }}
      />
      <StyledCardsLoader>
        <Skeleton variant="rounded" width="100%" height="7rem" />
      </StyledCardsLoader>
    </>
  );
};
