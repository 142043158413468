import { PostgrestError } from '@supabase/supabase-js';
import { useMutation } from '@tanstack/react-query';
import { ESTIMATES_TABLE, supabase } from '../../supabase';

type updateEstimateParams = {
  participant_id: string;
  estimate: string;
  voting_session_id: string;
  participant_name: string;
  room_id: string;
}

const updateEstimate = async (params: updateEstimateParams) => {
  const { error } = await supabase.from(ESTIMATES_TABLE).upsert(params);

  if (error) {
    throw error;
  }
};


export const useUpdateEstimate = () => {
  return useMutation<void, PostgrestError, updateEstimateParams>((params) =>
    updateEstimate(params)
  );
};
