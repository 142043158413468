import { createContext } from 'react';
import { RealtimeChannel, SupabaseClient } from '@supabase/supabase-js';

export const SupabaseContext = createContext<SupabaseClient | undefined>(
  undefined
);

SupabaseContext.displayName = 'SupabaseContext';

export const SupabaseProvider = SupabaseContext.Provider;
export const SupabaseConsumer = SupabaseContext.Consumer;

export const PresenceChannelContext = createContext<RealtimeChannel | null>(null);

PresenceChannelContext.displayName = 'PresenceChannelContext';

export const PresenceChannelProvider = PresenceChannelContext.Provider;
export const PresenceChannelConsumer = PresenceChannelContext.Consumer;
