import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Card } from '../Card/Card';
import { VotingSession } from '../../api/types';
import { AnonUser } from '../../api/hooks/database/use-create-user';

const CardsBlock = styled('div')(({ theme }) => ({
  label: 'CardsContainer',

  display: 'flex',
  gap: '10px',
  justifyContent: 'center',
  [theme.breakpoints.down('md')]: {
    width: '90%',
    justifyContent: 'flex-start',
    overflowX: 'auto',
    flexWrap: 'nowrap',
  },
}));

type CardsProps = {
  votingCardsValues: string[];
  onCardClick: (value: string) => void;
  latestVotingSession: VotingSession | null | undefined;
  user: AnonUser | undefined;
};

export const Cards = (props: CardsProps) => {
  const [selectedCard, setSelectedCard] = useState('');
  const { votingCardsValues, onCardClick, latestVotingSession, user } = props;

  const completedSession = latestVotingSession?.completed;

  useEffect(() => {
    latestVotingSession?.estimates.forEach((estimate) => {
      if (estimate.participant_id === user?.id) {
        setSelectedCard(estimate.estimate);
      }
    });
  }, [latestVotingSession, user]);

  useEffect(() => {
    if (completedSession) {
      setSelectedCard('');
    }
  }, [completedSession]);

  const onClick = (value: string) => {
    if (!latestVotingSession || completedSession) {
      return null;
    }

    onCardClick(value);
    setSelectedCard(value);
  };

  return (
    <CardsBlock>
      {votingCardsValues.map((value) => (
        <Card
          selected={value === selectedCard}
          value={value}
          key={value}
          onClick={() => onClick(value)}
        />
      ))}
    </CardsBlock>
  );
};
