import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { IconButton } from '../IconButtons/IconButton';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import { votingSystemOptions } from '../../pages/Home/CreateRoomForm';
import { Select, SelectChangeEvent } from '../Select/Select';
import { Input } from '../Input/Input';
import { Room, VotingSystem } from '../../api/types';
import { useUpdateRoom } from '../../api/hooks/database/use-update-room';
import { PrimaryButton } from '../Button/Button';
import DialogActions from '@mui/material/DialogActions';

const Container = styled('div')(({ theme }) => ({
  label: 'SettingsContainer',

  boxSizing: 'border-box',
  width: '30rem',
  backgroundColor: '#FFFFFF',
  padding: '2rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    width: '80vw',
  },
}));

const Title = styled('div')({
  label: 'Title',

  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: 20,
});

const SectionInputs = styled('div')({
  label: 'SectionInputs',

  display: 'flex',
  flexDirection: 'column',
  rowGap: 30,
  marginBottom: 40,
  width: '100%',
});

const SectionBtn = styled('div')({
  label: 'SectionBtn',

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
});

type RoomSettingsProps = {
  room: Room;
  onClose: () => void;
};

export const RoomSettings = (props: RoomSettingsProps) => {
  const { mutate: updateRoom } = useUpdateRoom();
  const { room, onClose } = props;
  const [formValues, setFormValues] = useState({
    roomName: room.title,
  });

  const [votingSystem, setVotingSystem] = useState(room?.voting_system);
  const [isRoomNameEmpty, setIsRoomNameEmpty] = useState(false);

  const handleInputKeyUp = (e: any) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });

    setIsRoomNameEmpty(validateIfEmpty(value));
  };

  const validateIfEmpty = (value: string) => {
    if (!value.trim().length) {
      return true;
    }
    return false;
  };

  const onVotingSystemChange = (e: SelectChangeEvent) => {
    setVotingSystem(e.target.value as VotingSystem);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    updateRoom({
      id: room.id,
      title: formValues.roomName,
      voting_system: votingSystem,
    });
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <Title>
          <Typography variant="subtitle1">Room's Settings</Typography>
          <IconButton onClick={handleClose}>
            <HighlightOffRoundedIcon sx={{ color: '#2C2B4F' }} />
          </IconButton>
        </Title>
        <SectionInputs>
          <Select
            votingSystemOptions={votingSystemOptions}
            votingSystem={votingSystem}
            onChange={onVotingSystemChange}
            label="Voting System"
          />
          <Input
            label={`Room's name`}
            name="roomName"
            defaultValue={formValues.roomName}
            onKeyUp={handleInputKeyUp}
            error={isRoomNameEmpty}
            errorText={'Field should not be empty'}
          />
        </SectionInputs>
        <SectionBtn>
          <DialogActions
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <PrimaryButton
              variant="contained"
              text="Update Settings"
              type="submit"
              onClick={handleClose}
            />
          </DialogActions>
        </SectionBtn>
      </Container>
    </form>
  );
};
