import { PostgrestError, PostgrestResponse } from '@supabase/supabase-js';
import { useQuery } from '@tanstack/react-query';
import { ROOMS_TABLE, supabase } from '../../supabase';
import { Room } from '../../types';

export type FetchRoomParams = {
  short_id: string;
};

const ROOM_NOT_FOUND = "room doesn't exist";

const fetchRoom = async (params: FetchRoomParams) => {
  const { error, data }: PostgrestResponse<Room> = await supabase
    .from(ROOMS_TABLE)
    .select(
      `*,
      voting_sessions (
        *,
        estimates (*)
      )
    `
    )
    .eq('short_id', params.short_id);

  if (error) {
    throw error;
  }

  const room = data[0];
  if (!room) {
    throw new Error(ROOM_NOT_FOUND);
  }

  return room;
};

export const useFetchRoom = (
  params: FetchRoomParams,
  placeholderData?: Room
) => {
  return useQuery<Room, PostgrestError>(
    ['fetchRoom', params],
    () => fetchRoom(params),
    {
      placeholderData,
    }
  );
};
