import { PostgrestError, PostgrestResponse } from '@supabase/supabase-js';
import { useMutation, useQuery } from '@tanstack/react-query';
import { VOTING_SESSIONS_TABLE, supabase } from '../../supabase';
import { VotingSession } from '../../types';

type CreateVotingSessionParams = {
  room_id: string;
  task_id?: string;
};

const createVotingSession = async (params: CreateVotingSessionParams) => {
  const { error }: PostgrestResponse<void> = await supabase
    .from(VOTING_SESSIONS_TABLE)
    .insert({
      ...params,
    });

  if (error) {
    throw error;
  }
};

export const useCreateVotingSession = () => {
  return useMutation<void, PostgrestError, CreateVotingSessionParams>(
    (params) => createVotingSession(params)
  );
};

export type FetchVotingSessionsParams = {
  room_id?: string;
};

const fetchVotingSessions = async (params: FetchVotingSessionsParams) => {
  if (!params.room_id) {
    return [];
  }

  const { error, data }: PostgrestResponse<VotingSession> = await supabase
    .from(VOTING_SESSIONS_TABLE)
    .select(
      `*,
      estimates (*)
    `
    )
    .eq('room_id', params.room_id);

  if (error) {
    throw error;
  }

  return data;
};

export const useFetchVotingSessions = (params: FetchVotingSessionsParams) => {
  return useQuery<VotingSession[], PostgrestError>(
    ['fetchVotingSessions', params],
    () => fetchVotingSessions(params)
  );
};

const fetchActiveVotingSession = async (params: FetchVotingSessionsParams) => {
  if (!params.room_id) {
    return null;
  }

  const { error, data }: PostgrestResponse<VotingSession> = await supabase
    .from(VOTING_SESSIONS_TABLE)
    .select(
      `*,
      estimates (*)
    `
    )
    .eq('room_id', params.room_id)
    .eq('completed', false);

  if (error) {
    throw error;
  }

  if (data.length > 1) {
    console.warn('WARN: Multiple active sessions are not supported');
  }

  if (!data.length) {
    return null;
  }

  return data[0];
};

export const useFetchActiveVotingSession = (
  params: FetchVotingSessionsParams
) => {
  return useQuery<VotingSession | null, PostgrestError>(
    ['fetchActiveVotingSession', params],
    () => fetchActiveVotingSession(params)
  );
};

const fetchLatestVotingSession = async (params: FetchVotingSessionsParams) => {
  if (!params.room_id) {
    return null;
  }

  const { error, data }: PostgrestResponse<VotingSession> = await supabase
    .from(VOTING_SESSIONS_TABLE)
    .select(
      `*,
      estimates (*)
    `
    )
    .eq('room_id', params.room_id)
    .order('created_at', { ascending: false })
    .limit(1);

  if (error) {
    throw error;
  }

  if (!data.length) {
    return null;
  }

  return data[0];
};

export const useFetchLatestVotingSession = (
  params: FetchVotingSessionsParams
) => {
  return useQuery<VotingSession | null, PostgrestError>(
    ['fetchLatestVotingSession', params],
    () => fetchLatestVotingSession(params)
  );
};

type UpdateVotingSession = {
  room_id: string;
  voting_session_id: string;
}

const closeVotingSession = async (params: UpdateVotingSession) => {
  const { error } = await supabase
    .from(VOTING_SESSIONS_TABLE)
    .update({
      completed: true,
    })
    .eq('room_id', params.room_id)
    .eq('id', params.voting_session_id);

  if (error) {
    throw error;
  }
};

export const useCloseVotingSession = () => {
  return useMutation<void, PostgrestError, UpdateVotingSession>((params) =>
    closeVotingSession(params)
  );
};
