import React from 'react';
import { IconButton } from './IconButton';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import Popover from '@mui/material/Popover';
import { CopyLinkBtn } from '../CopyLinkUI/CopyLinkBtn';
import { Typography } from '@mui/material';

const StyledIconBtn = styled(IconButton)({
  boxShadow: 'none',
  background: '#F1F1FF',
  borderRadius: '20%',
  color: '#2C2B4F',
  margin: '5px',
  padding: '12px',
  '&:hover': {
    background: '#EEEEFF',
  },
});

const PopoverContainer = styled('div')({
  label: 'PopoverContainer',

  display: 'flex',
  flexDirection: 'column',
  gap: '15px',
  justifyContent: 'center',
  alignItems: 'center',
});

export const CopyLink = () => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <Tooltip title="Invite teammates">
        <StyledIconBtn size="large" disableRipple onClick={handleClick}>
          <GroupAddOutlinedIcon fontSize="inherit" />
        </StyledIconBtn>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          '& .MuiPopover-paper': {
            width: '250px',
            height: 'fit-content',
            boxShadow:
              '0px 5px 5px -3px rgb(0 0 0 / 10%), 0px 7px 10px 1px rgb(0 0 0 / 10%), 0px 3px 14px 2px rgb(0 0 0 / 10%)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            padding: '20px',
            marginTop: '10px',
          },
        }}
      >
        <PopoverContainer>
          <Typography variant="body1">Copy the invite link</Typography>
          <CopyLinkBtn padding="5px" icon="small" />
        </PopoverContainer>
      </Popover>
    </>
  );
};
