import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Input } from '../../components/Input/Input';
import { Select, SelectChangeEvent } from '../../components/Select/Select';
import { PrimaryButton } from '../../components/Button/Button';
import { styled } from '@mui/material/styles';
import { useCreateRoom } from '../../api/hooks/database/use-create-room';
import { useCreateAnonUser } from '../../api/hooks/database/use-create-user';
import { generateShortId } from '../../utils/generate-id';
import { VotingSystem } from '../../api/types';

export const votingSystemOptions = {
  mFibonacci: {
    display: 'Modified Fibonacci (0, 1/2, 1, 2, 3, 5, 8, 13, 20, 40, 100, ?)',
    values: ['0', '1/2', '1', '2', '3', '5', '8', '13', '20', '40', '100', '?'],
  },
  fibonacci: {
    display: 'Fibonacci (0, 1, 2, 3, 5, 8, 13, 21, 34, 55, 89, ?)',
    values: ['0', '1', '2', '3', '5', '8', '13', '21', '34', '55', '89', '?'],
  },
  powersOfTwo: {
    display: 'Powers of 2 (0, 1, 2, 4, 8, 16, 32, 64)',
    values: ['0', '1', '2', '4', '8', '16', '32', '64'],
  },
  tShirts: {
    display: 'T-shirts (xxs, xs, s, m, l, xl, xxl)',
    values: ['xxs', 'xs', 's', 'm', 'l', 'xl', 'xxl'],
  },
} as { [key: string]: { display: string; values: string[] } };

const Container = styled('div')(({ theme }) => ({
  label: 'Container',

  width: '37.5rem',
  margin: '15vh auto 0',
  [theme.breakpoints.down('sm')]: {
    width: '90%',
  },
}));

const SectionInputs = styled('div')({
  label: 'SectionInputs',

  display: 'flex',
  flexDirection: 'column',
  rowGap: 30,
  marginBottom: 80,
});

const SectionBtn = styled('div')({
  label: 'SectionBtn',

  textAlign: 'center',
});

export const Form = () => {
  const { createAnonUser, user } = useCreateAnonUser();
  const [formValues, setFormValues] = useState({
    userName: user?.name || '',
    roomName: '',
  });
  const [votingSystem, setVotingSystem] = useState<VotingSystem>('mFibonacci');
  const navigate = useNavigate();
  const { mutate: createRoom } = useCreateRoom();

  const [isUserNameEmpty, setIsUserNameEmpty] = useState(false);
  const [isRoomNameEmpty, setIsRoomNameEmpty] = useState(false);

  const handleInputKeyUp = (e: any) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });

    if (name === 'userName') {
      setIsUserNameEmpty(validateIfEmpty(value));
    }

    if (name === 'roomName') {
      setIsRoomNameEmpty(validateIfEmpty(value));
    }
  };

  const validateIfEmpty = (value: string) => {
    if (!value.trim().length) {
      return true;
    }
    return false;
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (
      validateIfEmpty(formValues.userName) ||
      validateIfEmpty(formValues.roomName)
    ) {
      setIsUserNameEmpty(validateIfEmpty(formValues.userName));
      setIsRoomNameEmpty(validateIfEmpty(formValues.roomName));

      return;
    }

    createRoom(
      { title: formValues.roomName, voting_system: votingSystem },
      {
        onSuccess: (room) => {
          createAnonUser({
            id: generateShortId(),
            name: formValues.userName,
            isViewer: false,
          });
          navigate(`/${room?.short_id}`, { state: { room } });
        },
        onError: (error) => {
          console.error(error);
        },
      }
    );
  };

  const onVotingSystemChange = (e: SelectChangeEvent) => {
    setVotingSystem(e.target.value as VotingSystem);
  };

  return (
    <Container>
      <form onSubmit={handleSubmit}>
        <SectionInputs>
          <Input
            label="Your name"
            name="userName"
            onKeyUp={handleInputKeyUp}
            error={isUserNameEmpty}
            defaultValue={formValues.userName}
            errorText={'Field should not be empty'}
          />
          <Input
            label={`Room's name`}
            name="roomName"
            onKeyUp={handleInputKeyUp}
            error={isRoomNameEmpty}
            errorText={'Field should not be empty'}
          />
          <Select
            votingSystemOptions={votingSystemOptions}
            votingSystem={votingSystem}
            onChange={onVotingSystemChange}
            label="Voting System"
          />
        </SectionInputs>
        <SectionBtn>
          <PrimaryButton variant="contained" text="Create Room" type="submit" />
        </SectionBtn>
      </form>
    </Container>
  );
};
