import React from 'react';
import { Timeline as MTimeline } from '@mui/lab';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { Typography } from '@mui/material';
import { styled } from '@mui/system';
import createRoomScreen from './Imgs/x3CreateRoom.png';
import copyLinkScreen from './Imgs/x3CopyLink.png';
import startVotingScreen from './Imgs/x3StartVoting.png';
import chooseCardScreen from './Imgs/x3ChooseCard.png';
import revealCardsScreen from './Imgs/x3RevealCards.png';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const helpContent = [
  {
    title: 'Create a room for estimations:',
    text: 'As a facilitator of the estimation session, provide the room with a unique name, choose voting system and create a room by clicking on the “Create Room” button.',
    img: createRoomScreen,
  },
  {
    title: 'Invite your teammates by sharing the invitation link:',
    text: 'Copy and share the invitation link with your team to join the estimation session. They can join the room by clicking on the link and entering their name.',
    img: copyLinkScreen,
  },
  {
    title: 'Start the voting session:',
    text: 'Once all participants have joined the room, clicks the "Start Voting" button to initiate the voting session.',
    img: startVotingScreen,
  },
  {
    title: 'Vote by choosing your card:',
    text: 'Teammates vote by choosing their card, which represents the amount of effort they think the task will require.',
    img: chooseCardScreen,
  },
  {
    title: 'Reveal cards and see the results:',
    text: 'After everybody votes click “Reveal Cards” button and the estimates will be shown on the screen. Discuss any discrepancies, and re-vote if necessary until a consensus is reached.',
    img: revealCardsScreen,
  },
];

const StyledTimelineSeparator = styled(TimelineSeparator)(({ theme }) => ({
  label: 'StyledTimelineSeparator',

  margin: '0 1.25rem',
  [theme.breakpoints.down('md')]: {
    margin: '0 0.5rem 0 0',
  },
}));

const StyledTimelineContent = styled(TimelineContent)(({ theme }) => ({
  label: 'StyledTimelineItem',

  [theme.breakpoints.down('md')]: {
    padding: '0.25rem',
  },
}));

const StyledTimeline = styled(MTimeline)(({ theme }) => ({
  label: 'StyledTimeline',

  gap: '2rem',
  [theme.breakpoints.down('md')]: {
    [`& .${timelineItemClasses.root}:before`]: {
      flex: 0,
      padding: 0,
      position: 'left',
    },
    padding: '0.25rem',
  },
  [theme.breakpoints.up('md')]: {
    [`& .${timelineItemClasses.root}:before`]: {
      position: 'alternate',
    },
  },
}));

const ImageShadow = styled('div')(({ theme }) => ({
  label: 'ImageShadow',

  maxHeight: '12.5rem',
  maxWidth: '22.813rem',
  border: '1px solid #ECECEC',
  boxShadow: '0px 4px 45px #ECECEC',
  borderRadius: '10px',
  [theme.breakpoints.down('md')]: {
    marginTop: '1rem',
  },
  [theme.breakpoints.down('xs')]: {
    display: 'none',
  },
}));

const TypoContainer = styled('div')({
  label: 'TypoContainer',

  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
});

type textProps = {
  title: string;
  text: string;
};

const Text = (props: textProps) => {
  const { title, text } = props;
  return (
    <TypoContainer>
      <Typography variant="subtitle1">{title}</Typography>
      <Typography variant="body2">{text}</Typography>
    </TypoContainer>
  );
};

type imageProps = {
  imgsrc: string;
};

const Image = (props: imageProps) => {
  const { imgsrc } = props;

  return (
    <ImageShadow>
      <img
        src={imgsrc}
        alt={imgsrc}
        loading="lazy"
        height="100%"
        width="100%"
      />
    </ImageShadow>
  );
};

type separatorProps = {
  step: number;
};

const Separator = (props: separatorProps) => {
  const { step } = props;

  return (
    <StyledTimelineSeparator>
      <TimelineDot
        sx={{
          width: '15px',
          height: '15px',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="body1">{step}</Typography>
      </TimelineDot>
      <TimelineConnector sx={{ borderRadius: '2px', marginTop: '1.3rem' }} />
    </StyledTimelineSeparator>
  );
};

type timelineItemProps = {
  title: string;
  text: string;
  img: string;
  step: number;
};

const LineItem = (props: timelineItemProps) => {
  const { title, text, img, step } = props;

  return (
    <TimelineItem>
      <TimelineOppositeContent
        sx={{
          textAlign: 'justify',
        }}
      >
        <Text title={title} text={text} />
      </TimelineOppositeContent>
      <Separator step={step} />
      <TimelineContent>
        <Image imgsrc={img} />
      </TimelineContent>
    </TimelineItem>
  );
};

const SmallTimelineItem = (props: timelineItemProps) => {
  const { title, text, img, step } = props;

  return (
    <TimelineItem>
      <Separator step={step} />
      <StyledTimelineContent>
        <Text title={title} text={text} />
        <Image imgsrc={img} />
      </StyledTimelineContent>
    </TimelineItem>
  );
};

export const Timeline = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <StyledTimeline position={matches ? 'right' : 'alternate'}>
      {matches
        ? helpContent.map((item, index) => (
            <SmallTimelineItem
              title={item.title}
              text={item.text}
              img={item.img}
              step={index + 1}
              key={index}
            />
          ))
        : helpContent.map((item, index) => (
            <LineItem
              title={item.title}
              text={item.text}
              img={item.img}
              step={index + 1}
              key={index}
            />
          ))}
    </StyledTimeline>
  );
};
