import React from 'react';
import { styled } from '@mui/material/styles';
import { Avatar } from '../Avatars/Avatar';
import { AvatarGroup } from '../Avatars/AvatarGroup';
import { Typography } from '@mui/material';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import { PresenceState } from '../../api/hooks/realtime/use-presence';
import { Estimate, VotingSession } from '../../api/types';
import Tooltip from '@mui/material/Tooltip';
import { CopyLinkBtn } from '../CopyLinkUI/CopyLinkBtn';

const BoardContainer = styled('div')(({ theme }) => ({
  label: 'BoardContainer',

  minWidth: '22rem',
  minHeight: '9.375rem',
  maxHeight: '32vh',
  boxSizing: 'border-box',
  padding: '1.25rem',
  background: '#FFFFFF',
  border: '1px solid #ECECEC',
  boxShadow: '0px 4px 45px #ECECEC',
  borderRadius: '15px',
  margin: '0 auto',
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  overflow: 'auto',
  [theme.breakpoints.down('xs')]: {
    minWidth: '85%',
    width: '90%',
  },
}));

const ItemContainer = styled('div')({
  label: 'ItemContainer',

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '20px',
  margin: '10px',
});

const UsersContainer = styled('div')({
  label: 'UsersContainer',

  display: 'flex',
  justifyContent: 'end',
  minWidth: '70px',
});

const Graph = styled('div')({
  label: 'Graph',

  height: '35px',
  width: '130px',
  position: 'relative',
});

const StyledCircle = styled('div')({
  label: 'Circle',

  height: '35px',
  width: '35px',
  color: '#2C2B4F',
  fontSize: 'medium',
  fontFamily: 'Comfortaa',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: `#CACFFF`,
  borderRadius: '50%',
  position: 'relative',
  zIndex: '9',
});

const StyledVotes = styled('div')({
  label: 'Votes',

  height: '35px',
  minWidth: '45px',
  borderRadius: '25px',
  backgroundColor: 'rgba(202, 207, 255, 0.4)',
  position: 'absolute',
  top: '0',
  left: '0',
});

const Container = styled('div')({
  label: 'CopyLinkContainer',

  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '30px',
});

const Circle = ({ value }: any) => {
  return <StyledCircle>{value}</StyledCircle>;
};

type votesProps = {
  votes: number;
  members: number;
};

export const Votes = (props: votesProps) => {
  const { votes, members } = props;
  const width = Math.floor((+votes * 100) / +members);

  return <StyledVotes sx={{ width: `${width}%` }} />;
};

const User = ({ name = '', letter = '' }: { name: string; letter: string }) => {
  return (
    <Tooltip title={name}>
      <Avatar>{letter}</Avatar>
    </Tooltip>
  );
};

type votedUsersProps = {
  votedUsers: string[];
};

const VotedUsers = (props: votedUsersProps) => {
  const { votedUsers } = props;

  return (
    <UsersContainer>
      <AvatarGroup max={5}>
        {votedUsers.map((user) => (
          <User name={user} letter={user[0]} key={user} />
        ))}
      </AvatarGroup>
    </UsersContainer>
  );
};

type VotingItemProps = {
  estimation: string;
  votedUsers: string[];
  votes: number;
};

const Item = (props: VotingItemProps) => {
  const { estimation, votedUsers, votes } = props;

  return (
    <ItemContainer>
      <VotedUsers votedUsers={votedUsers} />
      <Graph>
        <Circle value={estimation} />
        <Votes votes={votedUsers.length} members={votes} />
      </Graph>
    </ItemContainer>
  );
};

type votingProcessProps = {
  members: number;
} & votedUsersProps;

const VotingProcess = (props: votingProcessProps) => {
  return (
    <>
      <Typography variant="subtitle1" mb={2}>
        Voting...
      </Typography>
      <ItemContainer>
        <VotedUsers votedUsers={props.votedUsers} />
        <Graph>
          <Circle value={<DoneRoundedIcon />} />
          <Votes votes={props.votedUsers.length} members={props.members} />
        </Graph>
      </ItemContainer>
    </>
  );
};

type resultProps = {
  votes: Estimate[];
};

const Result = (props: resultProps) => {
  const { votes } = props;

  const groupedResult = votes.reduce((result, vote) => {
    const votedUsers = result[vote.estimate];
    if (votedUsers) {
      votedUsers.push(vote.participant_name);
    } else {
      result[vote.estimate] = [vote.participant_name];
    }
    return result;
  }, {} as { [key: string]: string[] });

  return (
    <>
      <Typography variant="subtitle1" mb={2}>
        Votes: {votes.length}
      </Typography>
      {Object.entries(groupedResult)
        .sort(([, a], [, b]) => b.length - a.length)
        .map(([key, value]) => {
          return (
            <Item
              estimation={key}
              votedUsers={value}
              votes={votes.length}
              key={key}
            />
          );
        })}
    </>
  );
};

const CopyLink = () => {
  return (
    <Container>
      <Typography variant="subtitle1">
        Copy link to invite your teammates
      </Typography>
      <CopyLinkBtn />
    </Container>
  );
};

export type BoardStateProps = {
  onlineUsers: PresenceState[];
  activeVotingSession?: VotingSession | null | undefined;
  latestVotingSession?: VotingSession | null | undefined;
};

const BoardState = (props: BoardStateProps) => {
  const { onlineUsers, latestVotingSession } = props;

  const completedSession = latestVotingSession?.completed;

  const votedUsers = latestVotingSession?.estimates.map((estimate) => {
    return estimate.participant_name;
  });

  let currentUIState = 1;

  if (latestVotingSession && !completedSession) {
    currentUIState = 2;
  }

  if (completedSession) {
    currentUIState = 3;
  }

  switch (currentUIState) {
    case 1:
      return <CopyLink />;
    case 2:
      return (
        <VotingProcess
          votedUsers={votedUsers || []}
          members={onlineUsers.length}
        />
      );
    case 3:
      return <Result votes={latestVotingSession?.estimates || []} />;
    default:
      return null;
  }
};

export const Board = (props: BoardStateProps) => {
  return (
    <BoardContainer>
      <BoardState {...props} />
    </BoardContainer>
  );
};
