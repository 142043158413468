import { createClient } from '@supabase/supabase-js';

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL || '';
const supabaseKey = process.env.REACT_APP_SUPABASE_PUBLIC_KEY || '';

if (!supabaseUrl && !supabaseKey) {
  throw new Error('supabaseUrl and supabaseKey must be set.');
}

export const supabase = createClient(supabaseUrl, supabaseKey);

export const ROOMS_TABLE = 'rooms';

export const VOTING_SESSIONS_TABLE = 'voting_sessions';

export const ESTIMATES_TABLE = 'estimates';
