import React from 'react';
import { TextField } from '@mui/material';

type InputProps = {
  name: string;
  label: string;
  error: boolean;
  defaultValue?: string;
  errorText?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
};

export const Input = (props: InputProps) => {
  const {
    error,
    errorText = '',
    label,
    name,
    onChange,
    onKeyUp,
    defaultValue,
  } = props;
  return (
    <TextField
      fullWidth
      error={error}
      helperText={error && errorText}
      name={name}
      label={label}
      variant="outlined"
      size="small"
      onChange={onChange}
      onKeyUp={onKeyUp}
      defaultValue={defaultValue}
    />
  );
};
