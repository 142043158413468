import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { FormControl, Select as MSelect } from '@mui/material/';
import { SelectChangeEvent as MSelectChangeEvent } from '@mui/material/Select';

type SelectProps = {
  votingSystemOptions: { [key: string]: { display: string } };
  votingSystem: string;
  onChange: (e: MSelectChangeEvent) => void;
  label: string;
};

export type SelectChangeEvent = MSelectChangeEvent;

export const Select = (props: SelectProps) => {
  const { votingSystemOptions, votingSystem, onChange, label } = props;

  return (
    <FormControl fullWidth size="small">
      <InputLabel>Voting System</InputLabel>
      <MSelect value={votingSystem} label={label} onChange={onChange}>
        {Object.keys(votingSystemOptions).map((key) => (
          <MenuItem key={key} value={key}>
            {votingSystemOptions[key].display}
          </MenuItem>
        ))}
      </MSelect>
    </FormControl>
  );
};
