import React from 'react';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Logo } from '../../components/Logo/logo';
import { Menu } from '../../components/Menu/RoomMenu';
import { CopyLink } from '../../components/IconButtons/CopyLinkBtn';
// import { ShowTicket } from '../../components/IconButtons/ShowTicketsBtn';
import { ProfileTeamBlock } from '../../components/ProfileTeamBlock/ProfileTeamBlock';
import { Room } from '../../api/types';
import { PresenceState } from '../../api/hooks/realtime/use-presence';
import { Skeleton } from '@mui/material';

const HeaderContainer = styled('div')(({ theme }) => ({
  label: 'HeaderContainer',

  height: '6.3rem',
  margin: '2rem 2rem 7vh',
  textAlign: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    margin: '1.5rem 1rem 7vh',
  },
  [theme.breakpoints.down('xs')]: {
    flexDirection: 'column-reverse',
    height: 'fit-content',
  },
}));

const RoomName = styled('div')(({ theme }) => ({
  label: 'RoomName',

  display: 'flex',
  justifyContent: 'center',
  width: '30%',
  [theme.breakpoints.down('sm')]: {
    justifyContent: 'start',
    width: '45%',
  },
  [theme.breakpoints.down('xs')]: {
    justifyContent: 'center',
    marginTop: '2rem',
    width: '90%',
  },
}));

const LogoContainer = styled('div')(({ theme }) => ({
  label: 'Logo',

  width: '30%',
  display: 'flex',
  justifyContent: 'flex-start',
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

const Menus = styled('div')(({ theme }) => ({
  label: 'Menus',

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  width: '30%',
  [theme.breakpoints.down('sm')]: {
    width: '45%',
  },
  [theme.breakpoints.down('xs')]: {
    justifyContent: 'space-between',
    width: '95%',
  },
}));

const IconMenus = styled('div')({
  label: 'IconMenus',

  marginLeft: '10px',
});

type HeaderProps = {
  room?: Room;
  participants: PresenceState[];
};

export const Header = (props: HeaderProps) => {
  const { room, participants } = props;
  return (
    <HeaderContainer>
      <LogoContainer>
        <Link to="/">
          <Logo />
        </Link>
      </LogoContainer>
      <RoomName>
        {room ? (
          <Menu open={true} room={room} />
        ) : (
          <Skeleton variant="rounded" width="8.125rem" height="2.188rem" />
        )}
      </RoomName>
      <Menus>
        <ProfileTeamBlock participants={participants} />
        <IconMenus>
          <CopyLink />
          {/* <ShowTicket /> */}
        </IconMenus>
      </Menus>
    </HeaderContainer>
  );
};
