import React, { useState } from 'react';
import { MenuButton } from '../Button/Button';
import { Menu as MMenu, MenuProps } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { styled, alpha } from '@mui/material/styles';
import { Room } from '../../api/types';
import { Modal } from '../Modal/Modal';
import { RoomSettings } from '../SettingsMenus/RoomSettings';
import { Help } from './Help';

type MyMenuProps = {
  room?: Room;
} & MenuProps;

const StyledMenu = styled((props: MenuProps) => (
  <MMenu
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiMenu-list': {
    padding: '4px 0',
  },
  '& .MuiMenuItem-root': {
    '& .MuiSvgIcon-root': {
      fontSize: 20,
      color: theme.palette.text.primary,
      marginRight: theme.spacing(1.5),
    },
    '&:active': {
      backgroundColor: alpha(
        theme.palette.primary.main,
        theme.palette.action.selectedOpacity
      ),
    },
  },
}));

export const Menu = (props: MyMenuProps) => {
  const { room } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [openRoomSettingsModal, setOpenRoomSettingsModal] = useState(false);
  const [openHelpModal, setOpenHelpModal] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setOpenRoomSettingsModal(false);
    setOpenHelpModal(false);
    setAnchorEl(null);
  };

  const handleSettingsClick = () => {
    setOpenRoomSettingsModal(true);
    setAnchorEl(null);
  };

  const handleHelpClick = () => {
    setOpenHelpModal(true);
    setAnchorEl(null);
  };

  if (!room) {
    return null;
  }

  return (
    <>
      <MenuButton
        text={room.title}
        disableRipple
        endIcon={<KeyboardArrowDownIcon />}
        onClick={handleClick}
      />
      <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={handleSettingsClick}>
          <SettingsOutlinedIcon />
          Room Settings
        </MenuItem>
        <MenuItem onClick={handleHelpClick}>
          <HelpOutlineOutlinedIcon />
          Help
        </MenuItem>
      </StyledMenu>
      <Modal open={openRoomSettingsModal}>
        <RoomSettings room={room} onClose={handleClose} />
      </Modal>
      <Modal open={openHelpModal}>
        <Help onClose={handleClose} />
      </Modal>
    </>
  );
};
