import React from 'react';
import { styled } from '@mui/material/styles';
import { Board } from './Board';
import { Typography } from '@mui/material';
import { Cards } from '../Card/CardsBlock';
import { votingSystemOptions } from '../../pages/Home/CreateRoomForm';
import {
  useCreateVotingSession,
  useCloseVotingSession,
  useFetchLatestVotingSession,
} from '../../api/hooks/database/use-voting-session';
import { PresenceState } from '../../api/hooks/realtime/use-presence';
import { useUpdateEstimate } from '../../api/hooks/database/use-update-estimate';
import { useGetAnonUser } from '../../api/hooks/database/use-create-user';
import { Loader } from '../Loaders/VotingUILoader';
import { DisplayButton } from '../Button/RevealCardButton';

const VotingContainer = styled('div')({
  label: 'VotingContainer',

  margin: '0 auto 3rem',
  maxWidth: '800px',
  textAlign: 'center',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  flexDirection: 'column',
});

const BoardContainer = styled('div')({
  label: 'BoardContainer',

  minHeight: '25vh',
  marginBottom: '7vh',
});

type VotingUIProps = {
  votingSystem: string;
  roomId: string;
  onlineUsers: PresenceState[];
};

export const VotingUI = (props: VotingUIProps) => {
  const { votingSystem, roomId } = props;

  const { mutate: createVotingSession } = useCreateVotingSession();
  const { mutate: updateEstimate } = useUpdateEstimate();
  const { mutate: closeVotingSessiion } = useCloseVotingSession();
  const { data: latestVotingSession, isFetched } = useFetchLatestVotingSession({
    room_id: roomId,
  });

  const user = useGetAnonUser();

  const onUpdateEstimate = (value: string) => {
    if (
      !user?.id ||
      !user?.name ||
      !latestVotingSession?.id ||
      latestVotingSession?.completed
    ) {
      return;
    }

    updateEstimate({
      estimate: value,
      participant_id: user.id,
      participant_name: user.name,
      room_id: roomId,
      voting_session_id: latestVotingSession.id,
    });
  };

  const onStartVotingSession = () => {
    if (latestVotingSession && !latestVotingSession?.completed) {
      return;
    }

    createVotingSession({ room_id: roomId });
  };

  const onCloseVotingSession = () => {
    if (!latestVotingSession) {
      return null;
    }

    closeVotingSessiion({
      room_id: roomId,
      voting_session_id: latestVotingSession.id,
    });
  };

  return (
    <VotingContainer>
      {isFetched ? (
        <>
          <BoardContainer>
            <Board {...props} latestVotingSession={latestVotingSession} />
          </BoardContainer>
          <DisplayButton
            latestVotingSession={latestVotingSession}
            onCloseVotingSession={onCloseVotingSession}
            onStartVotingSession={onStartVotingSession}
          />
          <Typography variant="subtitle1" sx={{ margin: '10vh auto 0.5rem' }}>
            Choose your card
          </Typography>
          <Cards
            votingCardsValues={votingSystemOptions[votingSystem].values}
            onCardClick={onUpdateEstimate}
            latestVotingSession={latestVotingSession}
            user={user}
          />
        </>
      ) : (
        <Loader />
      )}
    </VotingContainer>
  );
};
