import React from 'react';
import { IconButton as MIconButton } from '@mui/material';

export const IconButton = React.forwardRef((props: any, ref: any) => {
  return (
    <MIconButton
      {...props}
      ref={ref}
      disableRipple
      sx={{
        padding: '0',
        '&:hover': {
          background: 'none',
        },
      }}
    />
  );
});
