import { QueryClient, useQueryClient } from '@tanstack/react-query';
import {
  RealtimePostgresChangesPayload,
  REALTIME_LISTEN_TYPES,
  REALTIME_POSTGRES_CHANGES_LISTEN_EVENT,
} from '@supabase/supabase-js';
import { useClient } from '../use-client';
import {
  ROOMS_TABLE,
  VOTING_SESSIONS_TABLE,
  ESTIMATES_TABLE,
} from '../../supabase';
import { Estimate, Room, VotingSession } from '../../types';
import { FetchVotingSessionsParams } from '../database/use-voting-session';
import { FetchEstimatesParams } from '../database/use-fetch-estimate';

type DatabaseUpdatesOptions = {
  roomId: string;
};

export const PG_UPDATES_CHANNEL_PREFIX = 'PG_UPDATES_CHANNEL_';
export const PG_ROOM_CHANNEL_PREFIX = 'PG_UPDATES_ROOM_';

const voting_session_updates = (
  payload: RealtimePostgresChangesPayload<VotingSession>,
  queryClient: QueryClient,
  queryParams: FetchVotingSessionsParams
) => {
  const newVotingSession = payload.new as VotingSession;

  if (!Object.keys(newVotingSession).length) {
    return;
  }

  queryClient.invalidateQueries(['fetchLatestVotingSession', queryParams]);
};

const voting_estimates_updates = (
  payload: RealtimePostgresChangesPayload<Estimate>,
  queryClient: QueryClient,
  queryParams: FetchEstimatesParams
) => {
  const newVotingEstimate = payload.new as Estimate;

  if (!Object.keys(newVotingEstimate).length) {
    return;
  }

  queryClient.invalidateQueries({ queryKey: ['fetchLatestVotingSession', queryParams] })
};

export const useDatabaseUpdates = () => {
  const client = useClient();
  const queryClient = useQueryClient();

  const subscribe = (config: DatabaseUpdatesOptions) => {
    const { roomId } = config;

    if (!roomId) {
      throw new Error('roomId is not defined');
    }

    const channel = client.channel(`${PG_UPDATES_CHANNEL_PREFIX}${roomId}`);

    const pgChangesConfigs: {
      event: `${REALTIME_POSTGRES_CHANGES_LISTEN_EVENT}`;
      schema: string;
      table?: string;
      filter?: string;
      handler: (payload: any) => void;
    }[] = [
      {
        event: 'INSERT',
        schema: 'public',
        table: VOTING_SESSIONS_TABLE,
        filter: `room_id=eq.${roomId}`,
        handler: (payload: RealtimePostgresChangesPayload<VotingSession>) =>
          voting_session_updates(payload, queryClient, { room_id: roomId }),
      },
      {
        event: 'UPDATE',
        schema: 'public',
        table: VOTING_SESSIONS_TABLE,
        filter: `room_id=eq.${roomId}`,
        handler: (payload: RealtimePostgresChangesPayload<VotingSession>) =>
          voting_session_updates(payload, queryClient, { room_id: roomId }),
      },
      {
        event: 'INSERT',
        schema: 'public',
        table: ESTIMATES_TABLE,
        filter: `room_id=eq.${roomId}`,
        handler: (payload: RealtimePostgresChangesPayload<Estimate>) =>
          voting_estimates_updates(payload, queryClient, { room_id: roomId }),
      },
      {
        event: 'UPDATE',
        schema: 'public',
        table: ESTIMATES_TABLE,
        filter: `room_id=eq.${roomId}`,
        handler: (payload: RealtimePostgresChangesPayload<Estimate>) =>
          voting_estimates_updates(payload, queryClient, { room_id: roomId }),
      },
    ];

    pgChangesConfigs.forEach((pgConfig) => {
      const { event, schema, table, filter, handler } = pgConfig;

      channel.on(
        REALTIME_LISTEN_TYPES.POSTGRES_CHANGES,
        {
          event,
          schema,
          table,
          filter,
        },
        handler
      );
    });

    channel.subscribe((status) => {
      console.info(
        `=> Subscribe DB: channel ${roomId} with the status: ${status}`
      );
    });

    return () => {
      channel.unsubscribe().then((status) => {
        console.info(
          `=> Unsubscribe DB: channel ${roomId} with the status: ${status}`
        );
      });
    };
  };

  return { subscribe };
};

type RoomUpdatesOptions = {
  short_id: string;
  roomId: string;
};

export const UseRoomUpdates = () => {
  const client = useClient();
  const queryClient = useQueryClient();

  const subscribe = (config: RoomUpdatesOptions) => {
    const { short_id, roomId } = config;

    if (!roomId) {
      throw new Error('roomId is not defined');
    }

    const channel = client.channel(`${PG_ROOM_CHANNEL_PREFIX}${roomId}`);

    channel.on(
      REALTIME_LISTEN_TYPES.POSTGRES_CHANGES,
      {
        event: 'UPDATE',
        schema: 'public',
        table: ROOMS_TABLE,
        filter: `id=eq.${roomId}`,
      },
      (payload: RealtimePostgresChangesPayload<Room>) => {
        const newRoomData = payload.new as Room;

        if (!Object.keys(newRoomData).length) {
          return;
        }

        queryClient.setQueryData(
          ['fetchRoom', { short_id }],
          (staleData: Room | undefined) => {
            if (!staleData) {
              return staleData;
            }

            return { ...staleData, ...newRoomData};
          }
        );
      }
    );

    channel.subscribe((status) => {
      console.info(
        `=> Subscribe Room: channel ${roomId} with the status: ${status}`
      );
    });

    return () => {
      channel.unsubscribe().then((status) => {
        console.info(
          `=> Unsubscribe Room: channel ${roomId} with the status: ${status}`
        );
      });
    };
  };

  return { subscribe };
};
