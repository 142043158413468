import React from 'react';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { IconButton } from '../IconButtons/IconButton';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import { Timeline } from '../Timeline/Timeline';

const Container = styled('div')(({ theme }) => ({
  label: 'SettingsContainer',

  boxSizing: 'border-box',
  maxWidth: '60rem',
  maxHeight: '80vh',
  backgroundColor: '#FFFFFF',
  padding: '2rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    width: '80vw',
    padding: '1rem',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '0.5rem',
  },
}));

const Title = styled('div')({
  label: 'Title',

  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  marginBottom: '1rem',
});

const TimelineContainer = styled('div')({
  label: 'TimelineContainer',

  width: '100%',
});

type helpProps = {
  onClose: () => void;
};

export const Help = (props: helpProps) => {
  const { onClose } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Container>
      <Title>
        <IconButton onClick={handleClose}>
          <HighlightOffRoundedIcon sx={{ color: '#2C2B4F' }} />
        </IconButton>
      </Title>
      <Typography variant="subtitle1" align="center">
        Step-by-step guide to an agile or scrum estimation session with Luna:
      </Typography>
      <TimelineContainer>
        <Timeline />
      </TimelineContainer>
    </Container>
  );
};
