import React from 'react';
import { styled } from '@mui/material/styles';

type StyledCardProps = {
  selected: boolean;
};

const StyledCard = styled('div')<StyledCardProps>((props) => ({
  label: 'Card',

  width: '50px',
  minWidth: '50px',
  height: '100px',
  backgroundColor: props.selected ? '#E4E6FD' : '#F7F7FA',
  border: '1px solid #CACFFF',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '5px',
  fontSize: '25px',
  fontFamily: 'Comfortaa',
  color: '#2C2B4F',
  '&:hover': {
    backgroundColor: props.selected ? '#E4E6FD' : '#F1F1FF',
    cursor: 'pointer',
  },
  [props.theme.breakpoints.down('md')]: {
    '&:hover': {
      backgroundColor: props.selected ? '#E4E6FD' : '#F7F7FA',
    },
  },
}));

type CardProps = {
  selected: boolean;
  onClick: () => void;
  value: string;
};

export const Card = (props: CardProps) => {
  return (
    <StyledCard selected={props.selected} onClick={props.onClick}>
      {props.value}
    </StyledCard>
  );
};
