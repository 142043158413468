import { SupabaseClient } from '@supabase/supabase-js';
import { useContext } from 'react';
import { SupabaseContext } from '../context';

export const useClient = (): SupabaseClient => {
  const client = useContext(SupabaseContext);

  if (!client) {
    throw Error('You must provide the supabase client using SupabaseProvider');
  }

  return client;
};
