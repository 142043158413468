import { PostgrestError } from '@supabase/supabase-js';
import { useMutation } from '@tanstack/react-query';
import { ROOMS_TABLE, supabase } from '../../supabase';
import { VotingSystem } from '../../types';

type UpdateRoomParams = {
  title: string;
  id: string;
  voting_system: VotingSystem;
};

const updateRoom = async (params: UpdateRoomParams) => {
  const { error } = await supabase
    .from(ROOMS_TABLE)
    .update(params)
    .eq('id', params.id);

  if (error) {
    throw error;
  }
};

export const useUpdateRoom = () => {
  return useMutation<void, PostgrestError, UpdateRoomParams>((params) =>
    updateRoom(params)
  );
};
