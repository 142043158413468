/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { usePresence } from '../../api/hooks/realtime/use-presence';
import { useFetchRoom } from '../../api/hooks/database/use-fetch-room';
import { Join } from '../../components/JoinRoom/Join';
import { Modal } from '../../components/Modal/Modal';
import { useGetAnonUser } from '../../api/hooks/database/use-create-user';
import {
  useDatabaseUpdates,
  UseRoomUpdates,
} from '../../api/hooks/realtime/use-database-updates';
import { Header } from '../../components/Header/Header';
import { VotingUI } from '../../components/Voting Board/VotingUI';
import { PresenceChannelProvider } from '../../api/context';
import { Loader } from '../../components/Loaders/VotingUILoader';
import { styled } from '@mui/material/styles';

const SkeletonContainer = styled('div')({
  margin: '0 auto',
  maxWidth: '800px',
  textAlign: 'center',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  flexDirection: 'column',
});

export const Room = () => {
  const { state: routeState } = useLocation();
  const { shortId = '' } = useParams();

  const { data: room } = useFetchRoom({ short_id: shortId }, routeState?.room);

  const {
    presence: onlineUsers,
    subscribe: onlineUsersSubscribe,
    channel: presenceChannel,
  } = usePresence();
  const { subscribe: databaseSubscribe } = useDatabaseUpdates();
  const { subscribe: roomUpdatesSubscribe } = UseRoomUpdates();

  const [openModal, setOpenModal] = useState(false);

  const user = useGetAnonUser();

  useEffect(() => {
    if (!user) {
      setOpenModal(true);
    }

    // clear the initial room state after redirect from the createRoom page
    window.history.replaceState({}, document.title);
  }, []);

  const onJoinRoom = () => {
    setOpenModal(!openModal);
  };

  useEffect(() => {
    if (!shortId || !room?.id || !user?.id) {
      return;
    }

    const cancelOnlinePresence = onlineUsersSubscribe({
      userId: user.id,
      userName: user.name,
      roomId: room.id,
      isViewer: user.isViewer,
    });

    const cancelRoomUpdates = roomUpdatesSubscribe({
      roomId: room.id,
      short_id: shortId,
    });

    const cancelDBUpdates = databaseSubscribe({
      roomId: room.id,
    });

    return () => {
      cancelOnlinePresence();
      cancelRoomUpdates();
      cancelDBUpdates();
    };
  }, [shortId, room?.id, user?.id]);

  return (
    <PresenceChannelProvider value={presenceChannel}>
      <Header room={room} participants={onlineUsers} />
      <Modal open={openModal}>
        <Join onCloseModal={onJoinRoom} />
      </Modal>
      {room ? (
        <VotingUI
          votingSystem={room.voting_system}
          onlineUsers={onlineUsers}
          roomId={room.id}
        />
      ) : (
        <SkeletonContainer>
          <Loader />
        </SkeletonContainer>
      )}
    </PresenceChannelProvider>
  );
};
