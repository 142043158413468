import React from 'react';
import { Avatar as MAvatar } from '@mui/material';

export const Avatar = React.forwardRef((props: any, ref) => {
  return (
    <MAvatar
      {...props}
      ref={ref}
      sx={{
        width: 32,
        height: 32,
        // margin: '0 -5px',
        backgroundColor: `#CACFFF`,
        border: '1px solid #7785FF',
        color: '#2C2B4F',
        fontSize: 'small',
      }}
    />
  );
});
