import { RealtimeChannel } from '@supabase/supabase-js';
import { useContext, useState } from 'react';
import { PresenceChannelContext } from '../../context';
import { useClient } from '../use-client';

type PresenceOptions = {
  roomId: string;
  userId: string;
  userName: string;
  isViewer: boolean;
};

export type PresenceState = {
  userId: string;
  userName: string;
  isViewer: boolean;
};

export const ONLINE_USERS_CHANNEL_PREFIX = 'ONLINE_USERS_CHANNEL_'

export const usePresence = () => {
  const client = useClient();

  const [presence, setPresence] = useState<PresenceState[]>([]);
  const [channel, setChannel] = useState<RealtimeChannel | null>(null);

  const subscribe = (config: PresenceOptions) => {
    const { roomId, userId, userName, isViewer } = config;

    if (!roomId || !userId) {
      throw new Error('roomId and userId are not defined');
    }

    const channel = client.channel(`${ONLINE_USERS_CHANNEL_PREFIX}${roomId}`, {
      config: {
        presence: { key: userId },
      },
    });

    setChannel(channel);

    channel
      .on('presence', { event: 'sync' }, () => {
        const state = channel.presenceState();

        const onlineUsers = Object.keys(state).reduce((users, presenceKey) => {
          const presenceProps = state[presenceKey];

          presenceProps.forEach((presenceProp) => {
            users.push({
              userId: presenceProp.userId,
              userName: presenceProp.userName,
              isViewer: presenceProp.isViewer
            });
          });

          return users;
        }, [] as PresenceState[]);

        setPresence(onlineUsers);
      })
      .subscribe((status) => {
        console.info(`=> Subscribe Users: channel ${roomId} with the status: ${status}`);

        if (status === 'SUBSCRIBED') {
          channel.track({
            userId,
            userName,
            isViewer,
          });
        }
      });

    return () => {
      channel.unsubscribe().then((status) => {
        console.info(
          `=> Unsubscribe Users: channel ${roomId} with status: ${status}`
        );
      });
    };
  };

  return { presence, channel, subscribe };
};

export const usePresenceChannel = (): RealtimeChannel | null => {
  const channel = useContext(PresenceChannelContext);

  if (!channel) {
    return null;
  }

  return channel;
};
