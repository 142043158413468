import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { useCreateAnonUser } from '../../api/hooks/database/use-create-user';
import { Input } from '../Input/Input';
import { PrimaryButton } from '../Button/Button';
import { generateShortId } from '../../utils/generate-id';

const Container = styled('div')(({ theme }) => ({
  label: 'JoinRoomContainer',

  boxSizing: 'border-box',
  backgroundColor: '#FFFFFF',
  padding: '3.125rem 2.5rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  [theme.breakpoints.down('xs')]: {
    padding: '3.125rem 1.5rem',
  },
}));

const StyledForm = styled('form')(({ theme }) => ({
  label: 'styledButton',

  [theme.breakpoints.down('sm')]: {
    maxWidth: '100%',
  },
}));

const InputContainer = styled('div')(({ theme }) => ({
  label: 'InputContainer',

  width: '400px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '20px',
  margin: '10px 0 50px',
  [theme.breakpoints.down('sm')]: {
    maxWidth: '100%',
  },
}));

const BtnContainer = styled('div')({
  label: 'ButtonContainer',

  textAlign: 'center',
});

type JoinProps = {
  onCloseModal: () => void;
};

export const Join = (props: JoinProps) => {
  const { onCloseModal } = props;

  const { createAnonUser } = useCreateAnonUser();
  const [userName, setUserName] = useState('');
  const [isUserNameEmpty, setIsUserNameEmpty] = useState(false);

  const handleInputKeyUp = (e: any) => {
    const { value } = e.target;
    setUserName(value);

    setIsUserNameEmpty(validateIfEmpty(value));
  };

  const validateIfEmpty = (value: string) => {
    if (!value.trim().length) {
      return true;
    }
    return false;
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (validateIfEmpty(userName)) {
      setIsUserNameEmpty(validateIfEmpty(userName));
      return;
    }

    createAnonUser({
      id: generateShortId(),
      name: userName,
      isViewer: false,
    });

    onCloseModal();
  };

  return (
    <Container>
      <StyledForm onSubmit={handleSubmit}>
        <InputContainer>
          <Input
            label="Your name"
            name="userName"
            onKeyUp={handleInputKeyUp}
            error={isUserNameEmpty}
            defaultValue={userName}
            errorText={'Field should not be empty'}
          />
        </InputContainer>
        <BtnContainer>
          <PrimaryButton variant="contained" text="Join Room" type="submit" />
        </BtnContainer>
      </StyledForm>
    </Container>
  );
};
