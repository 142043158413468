import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { SmallButton } from '../Button/Button';
import Tooltip from '@mui/material/Tooltip';
import InputBase from '@mui/material/InputBase';
import DoneAllRoundedIcon from '@mui/icons-material/DoneAllRounded';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';

const InputBtn = styled('div')({
  label: 'InputBtnContainer',

  width: '100%',
  display: 'flex',
  gap: '7px',
});

type CopyLinkProps = {
  padding?: string;
  fontSize?: string;
  icon?: 'small' | 'inherit' | 'medium' | 'large' | undefined;
  width?: string;
  height?: string;
};

export const CopyLinkBtn = (props: CopyLinkProps) => {
  const [isCopied, setIsCopied] = useState(false);
  const [openTooltip, setOpenTooltip] = useState(false);

  const onCopyClick = () => {
    navigator.clipboard.writeText(window.location.href);
    setIsCopied(true);
    setOpenTooltip(true);
    setTimeout(() => {
      setIsCopied(false);
      setOpenTooltip(false);
    }, 1500);
  };

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) =>
    event.target.select();

  return (
    <InputBtn>
      <InputBase
        defaultValue={window.location.href}
        fullWidth
        readOnly
        onFocus={handleFocus}
        sx={{
          '& .MuiInputBase-input': {
            border: '1px solid rgba(103, 115, 219, 0.5)',
            borderRadius: '4px',
            '&:hover': {
              borderColor: '#6773DB',
            },
            padding: [props.padding ? `${props.padding}` : '7px'],
            fontSize: `${props.fontSize}`,
          },
        }}
      />
      <Tooltip
        title="Copied"
        disableHoverListener
        open={openTooltip}
        placement="top"
        arrow
        sx={{ margin: '0' }}
      >
        <SmallButton
          variant="outlined"
          text={
            isCopied ? (
              <DoneAllRoundedIcon fontSize={props.icon} />
            ) : (
              <ContentCopyOutlinedIcon fontSize={props.icon} />
            )
          }
          onClick={onCopyClick}
          width={props.width}
          height={props.height}
        />
      </Tooltip>
    </InputBtn>
  );
};
