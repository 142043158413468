import React from 'react';
// import { Avatar } from './Avatar';
import {
  AvatarGroup as MAvatarGroup,
  AvatarGroupProps as MAvatarGroupProps,
} from '@mui/material';

type AvatarGroupProps = {} & MAvatarGroupProps;

export const AvatarGroup = (props: AvatarGroupProps) => {
  return (
    <MAvatarGroup
      {...props}
      sx={{
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        '& .MuiAvatar-root': {
          width: 32,
          height: 32,
          margin: '0 -5px',
          backgroundColor: `#CACFFF`,
          border: '1px solid #7785FF',
          color: '#2C2B4F',
          fontSize: 'small',
        },
      }}
    />
  );
};
