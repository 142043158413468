import React from 'react';
import { Button as MButton, ButtonProps } from '@mui/material';
import { styled } from '@mui/material/styles';

type MyBtnProps = {
  text: string;
} & ButtonProps;

export const MenuButton = (props: MyBtnProps) => {
  return (
    <MButton
      {...props}
      variant="text"
      sx={{
        color: '#2C2B4F',
        textTransform: 'none',
      }}
    >
      {props.text}
    </MButton>
  );
};

export const Button = (props: MyBtnProps) => {
  return (
    <MButton
      {...props}
      sx={{
        width: 230,
      }}
    >
      {props.text}
    </MButton>
  );
};

const StyledButton = styled(MButton)(({ theme }) => ({
  label: 'styledButton',

  width: '18.75rem',
  [theme.breakpoints.down('xs')]: {
    maxWidth: '90%',
    fontSize: '18px',
    padding: '0.375rem 0.5rem',
  },
}));

export const PrimaryButton = (props: MyBtnProps) => {
  return <StyledButton {...props}>{props.text}</StyledButton>;
};

export const LongButton = (props: any) => {
  return (
    <MButton
      {...props}
      sx={{
        width: 280,
        height: 40,
        fontSize: 18,
        fontWeight: 400,
        '& .MuiButton-startIcon': {
          marginRight: '15px',
        },
      }}
    >
      {props.text}
    </MButton>
  );
};

export const SmallButton = React.forwardRef((props: any, ref) => {
  return (
    <MButton
      {...props}
      ref={ref}
      sx={{
        minWidth: 40,
        width: `${props.width}`,
        height: `${props.height}`,
      }}
    >
      {props.text}
    </MButton>
  );
});
