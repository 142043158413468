import { PostgrestError, PostgrestResponse } from '@supabase/supabase-js';
import { useMutation } from '@tanstack/react-query';
import { generateShortId } from '../../../utils/generate-id';
import { ROOMS_TABLE, supabase } from '../../supabase';
import { Room, VotingSystem } from '../../types';


type CreateRoomParams = {
  title: string;
  voting_system: VotingSystem;
};

const createRoom = async (params: CreateRoomParams) => {
  const { error, data }: PostgrestResponse<Room> = await supabase
    .from(ROOMS_TABLE)
    .insert({
      ...params,
      short_id: generateShortId(),
    })
    .select();

  if (error) {
    throw error;
  }

  return data[0];
};

export const useCreateRoom = () => {
  return useMutation<Room, PostgrestError, CreateRoomParams>((params) =>
    createRoom(params)
  );
};
