import { useLocalStorage } from 'usehooks-ts';

export type AnonUser = {
  id: string;
  name: string;
  isViewer: boolean;
};

const LUNA_ANON_USER_KEY = 'LUNA_ANON_USER_KEY';

export const useCreateAnonUser = () => {
  const [user, setUser] = useLocalStorage<AnonUser | undefined>(LUNA_ANON_USER_KEY, undefined);

  const createAnonUser = (anonUser: AnonUser) => {
    setUser(anonUser);
  };

  return { user, createAnonUser };
};

export const useGetAnonUser = () => {
  const [user] = useLocalStorage<AnonUser | undefined>(
    LUNA_ANON_USER_KEY,
    undefined
  );
  return user;
};
