import React from 'react';
import { Form } from './CreateRoomForm';
import { styled } from '@mui/material/styles';
import { Logo } from '../../components/Logo/logo';

const LogoContainer = styled('div')({
  label: 'LogoContainer',

  width: '100%',
  padding: '2rem',
});

export const Home = () => {
  return (
    <>
      <LogoContainer>
        <Logo />
      </LogoContainer>
      <Form />
    </>
  );
};
