import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import { IconButton } from '../IconButtons/IconButton';
import { Avatar } from '../Avatars/Avatar';
import { AvatarGroup } from '../Avatars/AvatarGroup';
import { Popover, Typography } from '@mui/material';
import { useGetAnonUser } from '../../api/hooks/database/use-create-user';
import { PresenceState } from '../../api/hooks/realtime/use-presence';

const Block = styled('div')({
  label: 'ProfileParticipantsBlock',

  height: '42px',
  backgroundColor: '#F1F1FF',
  borderRadius: '10px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '5px 10px',
});

const VL = styled('div')({
  label: 'VercicalLine',

  height: '35px',
  width: '2px',
  borderRadius: '20px',
  backgroundColor: '#CACFFF',
  margin: '0 10px 0 5px',
});

const ParticipantsBlock = styled('div')({
  label: 'Participants',

  display: 'flex',
  margin: '0 5px',
});

const Participants = ({ participants }: { participants: PresenceState[] }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (participants.length === 0) {
    return null;
  }

  const open = Boolean(anchorEl);

  return (
    <ParticipantsBlock>
      <VL />
      <AvatarGroup max={4} onClick={handleClick}>
        {participants.map((participant) => (
          <User name={participant.userName[0]} key={participant.userId} />
        ))}
      </AvatarGroup>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        sx={{
          mt: 2,
          '& .MuiPopover-paper': {
            maxWidth: '240px',
            display: 'flex',
            flexWrap: 'wrap',
            padding: '10px',
            background: 'rgba(72, 71, 81, 0.9)',
            borderRadius: '15px',
          },
        }}
      >
        {participants.map((participant) => (
          <Team user={participant.userName} key={participant.userId} />
        ))}
      </Popover>
    </ParticipantsBlock>
  );
};

const UserContainer = styled('div')({
  width: '50px',
  display: 'flex',
  flexDirection: 'column',
  margin: '5px',
  alignItems: 'center',
});

const Team = ({ user = '' }: { user: string }) => {
  return (
    <UserContainer>
      <User name={user[0]} />
      <Typography
        variant="body2"
        sx={{ marginTop: '7px', color: '#fff', fontSize: '12px' }}
      >
        {user}
      </Typography>
    </UserContainer>
  );
};

const User = ({ name = '' }: { name: string }) => {
  return <Avatar>{name}</Avatar>;
};

export const ProfileTeamBlock = ({
  participants,
}: {
  participants: PresenceState[];
}) => {
  const currentUser = useGetAnonUser();

  if (!currentUser) {
    return null;
  }

  const team = participants.filter(
    (participant) => participant.userId !== currentUser.id
  );

  return (
    <Block>
      <Tooltip title={`You (${currentUser.name})`}>
        <IconButton mb={2}>
          <Avatar>{currentUser.name[0]}</Avatar>
        </IconButton>
      </Tooltip>
      <Participants participants={team} />
    </Block>
  );
};
