import React, { useState } from 'react';
import { Button } from './Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { VotingSession } from '../../api/types';

type DisplayBtnProps = {
  latestVotingSession: VotingSession | null | undefined;
  onCloseVotingSession: () => void;
  onStartVotingSession: () => void;
};

export const DisplayButton = (props: DisplayBtnProps) => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const { latestVotingSession, onCloseVotingSession, onStartVotingSession } =
    props;

  const completedSession = latestVotingSession?.completed;

  const onRevealCards = () => {
    if (
      !latestVotingSession?.estimates.length ||
      completedSession ||
      !latestVotingSession
    ) {
      setOpenSnackbar(true);
      return;
    }

    onCloseVotingSession();
  };

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };

  const action = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  if (latestVotingSession && !completedSession) {
    return (
      <>
        <Button
          text="Reveal Cards"
          variant="contained"
          onClick={onRevealCards}
          sx={{ margin: '1rem auto' }}
        />
        <Snackbar
          open={openSnackbar}
          autoHideDuration={5000}
          onClose={handleClose}
          message="Choose your card"
          action={action}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        />
      </>
    );
  }
  return (
    <Button
      text="Start Voting"
      variant="contained"
      onClick={onStartVotingSession}
      sx={{ margin: '1rem auto' }}
    />
  );
};
