import React from 'react';
import { Dialog } from '@mui/material';

type ModalProps = {
  open: boolean;
  children: any;
};

export const Modal = (props: ModalProps) => {
  const { open, children } = props;
  return (
    <Dialog
      open={open}
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: '20px',
          border: '1px solid #ECECEC',
          boxShadow: '0px 4px 30px rgba(11, 8, 119, 0.05)',
          maxWidth: '80vw',
        },
      }}
    >
      {children}
    </Dialog>
  );
};
