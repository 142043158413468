
import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
      light: '#7785FF',
      main: '#6773DB',
      dark: '#565FB9',
    },
    text: {
      primary: '#2C2B4F',
      secondary: '#B8B8C3',
    },
  },
  typography: {
    fontFamily: ['Comfortaa', 'Oxygen'].join(','),
    h1: {
      fontSize: 50,
    },
    button: {
      fontSize: 19,
      fontWeight: 600,
      fontFamily: 'Comfortaa',
      textTransform: 'capitalize',
    },
    body1: {
      fontSize: 17,
      fontFamily: 'Oxygen',
    },
    subtitle1: {
      fontSize: 18,
      fontFamily: 'Comfortaa',
    },
  },
  breakpoints: {
    values: {
      xs: 550,
      sm: 700,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});
